<template>
  <span @click="handle">
    <svg class="icon hover" aria-hidden="true">
      <use :xlink:href="icon"></use>
    </svg>
  </span>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: "#icon-Newitem",
    },
    handle: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
<style scoped lang='scss'>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
